import React from "react"
import Layout from "../components/layout"
import { Container, Row, Col } from 'react-bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import '../styles/global.scss'

export default () => (
  <Layout>
    <Container fluid className="bg-dark d-flex align-items-center vh-100">
      <Container>
        <Row className="mt-5 mb-5">
          <Col xs="12" className="text-center">
            <h3 className="text-white mb-4">
              Impressum
            </h3>
            <p className="text-white">
              Dr. Ernstfried Hanisch<br />
              Diplom-Psychologe
            </p>
            <p className="text-white">
              Novalisstraße 4<br />
              81369 München
            </p>
            <p className="text-white">
              tel 089-531654<br />
              mobil 0152-34564076<br />
              dr.e.hanisch@web.de
            </p>
            <p className="text-white">
              Konzept & Design:<br />
              Websites-aus-München<br />
              by initics GmbH<br />
              <a href="https://websites-aus-muenchen.de" target="_blank" rel="noreferrer noopener">
                https://websites-aus-muenchen.de/
              </a>
            </p>
          </Col>
        </Row>
      </Container>
    </Container>
  </Layout>
)
